<template>
	<div class="h-full flex flex-col">
		<TopBar :showHome="true" :showChat="true"/>
		<ProgressBar :current="4"/>
		<AppTitle :title="tableName" v-if="table"/>
		<div class="pt-2 text-gray-400 overflow-y-scroll flex-grow" v-if="table">
			<p>Select how much you are willing to spend</p>
			<div class="py-8">

				<div class="grid grid-cols-3 gap-4 mb-4">
					<button 
					class="text-center rounded-xl py-3 font-bold"
					@click="pricing=1"
					:class="pricing===1 ? 'bg-red-500 text-white' : 'bg-white text-red-500'"
					>$</button>
					<button 
					class="text-center rounded-xl py-3 font-bold"
					@click="pricing=2"
					:class="pricing===2 ? 'bg-red-500 text-white' : 'bg-white text-red-500'"
					>$$</button>
					<button 
					class="text-center rounded-xl py-3 font-bold"
					@click="pricing=3"
					:class="pricing===3 ? 'bg-red-500 text-white' : 'bg-white text-red-500'"
					>$$$</button>
				</div>
				<button 
				class="text-center rounded-xl py-3 font-bold w-full"
				@click="pricing=0"
				:class="pricing===0 ? 'bg-red-500 text-white' : 'bg-white text-red-500'"
				>No preference</button>

			</div>

		</div>

		<div id="bottom_buttons" class="w-full pt-10 pb-6 bg-gray-50">
			<div>
				<button class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg" @click="next">
					Next
				</button>
				<button class="text-gray-500 py-2 rounded-xl w-full font-gothic text-lg mt-4" @click="previous">
					Back
				</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import ProgressBar from '@/components/ProgressBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
	export default {
		components: {
			TopBar,
			AppTitle,
			ProgressBar,
		},
		data(){
			return {
				error: false
			}
		},
		computed: {
			tableName(){
				return this.table.name.length ? this.table.name : `Table ${this.table.code.toUpperCase()}`
			},
			table(){
				return this.$store.state.table.table;
			},
			pricing: {
				get(){
					return this.table.answers.pricing || 0;
				},
				set(value){
					this.$store.commit('table/SET_ANSWER', {
						name: 'pricing',
						value: value
					});
				}
			},
		},
		watch: {
			pricing(){
				this.error = false;
			}
		},
		methods: {
			isValid(){
				let ret = this.pricing > 0 && this.pricing < 5;
				if (!ret){
					this.error = true;
				}
				return ret;
			},
			next(){
				this.$store.dispatch('table/saveAnswers', {
					step: 5
				});
			},
			previous(){
				this.$store.dispatch('table/saveAnswers', {
					step: 3
				});
			},
		}
	}
</script>